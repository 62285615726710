<template>
  <LiefengContent>
    <template v-slot:title>社区用户</template>
    <template v-slot:toolsbarRight>
        <Form class="search" :inline="true">
          <FormItem>
           <Cascader @on-change="changeCasder" :load-data="loadData" change-on-select style="margin-right: 10px; width: 300px" :data="CascaderList" v-model="CascaderId" placeholder="请选择区/街道/社区"></Cascader>
          </FormItem>
          <FormItem>
            <Input v-model="formItem.name" placeholder="请输入姓名"></Input>
          </FormItem>
          <FormItem>
            <Input v-model="formItem.mobile" placeholder="请输入手机号"></Input>
          </FormItem>
            <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="search">搜索</Button>
            <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
            <Button type="primary" style="margin-left: 10px;" @click="$router.push('/verified?name=communityuser')">实名认证</Button>
        </Form>
      
      <!-- <Button type="info" style="margin-left: 10px;" @click="openMessage">发社区通知</Button> -->
      <!-- <Button type="info" style="margin-left: 10px;" @click="excelDataFn">导出</Button> -->
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
      </div>

      <!--发短信-->
      <LiefengModal :value="messageStatus"  @input="messageInput" title="发短信" :fullscreen="false" width="800px" height="540px">
          <template v-slot:contentarea>
              <Message @SmsUser="SmsUser" ref="message" :isShow="showNumber"></Message>
          </template>
          <template v-slot:toolsbar>
             <Button type="info" @click="messageBack" style="margin-right: 10px" >取消</Button>
            <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="messageSend">确定发送</Button>
          </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/communityuser')
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import Message from './childrens/message.vue'



export default {
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
    Message
  },
  data() {
    return {
      //搜索
      formItem: {},
      //table列表
      tableColumns: [
        {
          title: '区',
          key: 'zoneName',
          minWidth: 100,
          align: "center",
        },
        {
          title: '街道',
          key: 'streetName',
          align: "center",
          minWidth: 130
        },
        {
          title: '社区',
          key: 'orgName',
          align: "center",
          minWidth: 80,
        },
        {
          title: '姓名',
          key: 'name',
          minWidth: 130,
          align: "center",
        },
        {
          title: '手机号',
          key: 'account',
          minWidth: 100,
          align: "center",
        },
        {
          title: '性别',
          key: 'sex',
          minWidth: 50,
          align: "center",
          render:(h,params) =>{
            return h('div',{},params.row.sex == 0 ?'未知': params.row.sex == 1 ?'男' :params.row.sex == 2 ?'女':'未知')
          }
        },
        {
          title: '出生日期',
          key: 'birthday',
          minWidth: 100,
          align: "center",
        },
        // {
        //   title: '现住地址',
        //   key: 'addr',
        //   minWidth: 250,
        //   align: "center",
        // },
        {
          title: '加入时间',
          key: 'gmtCreate',
          minWidth: 170,
          align: "center",
          render:(h,params) =>{
            return h('div',{},params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate),"yyyy-MM-dd"):'')
          }
        },
        {
          title: '来源',
          key: 'dataSource',
          minWidth: 170,
          align: "center",
          // render:(h,params) =>{
          //   return h ('div',{},)
          // }
        },
        // {
        //   title: '操作',
        //   key: 'action',
        //   fixed: 'right',
        //   align: "center",
        //   minWidth: 180,
        //   render: (h, params) => {
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small',
        //         },
        //         style: {
        //         },
        //         on: {
        //           click: () => {
                    
        //           }
        //         }
        //       }, '修改'),
        //     ]);
        //   }
        // }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
      currentPage:0,

       // 发消息
      messageStatus:false,
      showNumber:0,
      sendDisabled:false,

      // 选择区街道社区
      CascaderList:[],
      CascaderId:[],
    };
  },
  created() {
    this.getList()
    this.getDict()
    this.getCascader()
  },
  methods: {

    // 点击发消息按钮
    openMessage(){
      this.messageStatus = true
      ++this.showNumber
      this.sendDisabled = false
    },
    // 发消息
    messageInput(status){
      this.messageStatus = status
    },
    // 弹窗返回按钮
    messageBack(){
      this.messageStatus = false
    },
    // 弹窗确认按钮
    messageSend(){
      if(this.$refs.message.selectList.length == 0){
        this.$Message.warning({
          content:'请选择接收人',
          background:true
        })
        return
      }
      if(this.$refs.message.formData.content == ''){
        this.$Message.warning({
          content:'请填写短信内容',
          background:true
        })
        return
      }
      let data = []
      this.$refs.message.selectList.map(item=>{
        data.push(item.id)
      })

     
    },
    // 分页选择的回调事件
    tableSelect(val){
      console.log(val);
    },
    // 分页修改的回调事件
    hadlePageSize(val){
      this.page = val.page
      this.pageSize = val.pageSize
      this.getList()
    },
    //搜索
    search() {
      if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
        this.$Message.warning({
          content:'请选择到社区进行查询',
          background:true
        })
        return
      }
      this.getList()
    },
    //重置
    reset() {
      this.formItem = {}
      this.CascaderId = []
      this.getList()
    },
    // 接口部分
    // 字典接口
    getDict(){
      this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "SY_USER_DATA_SOUCE",
      }).then(res=>{
        this.dictOptions = res.dataList
      })
    },
    // 点击加载下级
    loadData (item, callback) {
      item.loading = true
      let params = {
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:item.orgCode,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      };
       this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        ...params
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           let arr = this.forMatterTree(res.dataList)
           arr.map(items =>{
             if(items.orgLevel == 4){
               items.loading = false
             }
           })
           item.loading = false
           item.children = arr
           callback(item.children);
         }else{
           callback([]);
         }
      })
    },
    // 获取级联
    getCascader(){
      this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:'4401',
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           this.CascaderList = this.forMatterTree(res.dataList)
           this.CascaderList.map(item =>{
             item.loading = false
           })
         }
      })
    },
    // 获取分页信息
    getList(){
      this.loading = true
      this.$get('/syaa/api/syuser/pc/userCommunity/selectUserOrgPage',{
        page:this.page,
        pageSize:this.pageSize,
        orgCode:this.CascaderId && this.CascaderId.length == 3 ? this.CascaderId[this.CascaderId.length - 1] : '',
        oemCode:parent.vue.oemInfo.oemCode,
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
        ...this.formItem
      }).then(res=>{
        this.loading = false
        if(res.code == 200){
            this.tableData = res.dataList
            this.total = res.maxCount
            this.currentPage = res.currentPage
            if(this.tableData && this.tableData.length != 0){
              this.tableData.map(item =>{
                if(item.dataSource && item.dataSource != '' && item.dataSource != null){
                  this.dictOptions.map(items =>{
                    if(items.dictKey == item.dataSource){console.log('符合条件进来',items.dictValue); item.dataSource = items.dictValue}
                  })
                }else if(!item.dataSource || item.dataSource == '' || item.dataSource == null){
                  item.dataSource = '默认加入'
                }
              })
            }
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },


    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].orgName;
        data[i].value = data[i].orgCode;
         if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
         }
      }
      return data;
    },
  },
};
</script>

<style scoped lang='less'>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;
    
    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
</style>


